import React from 'react'
import Button from './Button'
import { Link } from 'gatsby'
import { Phone, Message } from './Icons'
import './GetInTouch.scss'

const GetInTouch = () => (
  <section className="GetInTouch">
    <h2 className="GetInTouch_heading">Get a free quote</h2>
    <div className="GetInTouch_contentWrap">
      <a href="tel:07710537685">
        <Button color="accent" className="GetInTouch_callButton">
          <Phone />
          Call us
        </Button>
      </a>
      <Link to="/contact">
        <Button color="accent2" className="GetInTouch_messageButton">
          <Message />
          Message us
        </Button>
      </Link>
    </div>
  </section>
)

export default GetInTouch
