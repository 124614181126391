import React from 'react'
import PropTypes from 'prop-types'
import Tilt from 'react-tilt'
import Img from 'gatsby-image'
import classNames from 'classnames'
import { Link } from 'gatsby'
import getCoverPicture from '../utilities/getCoverPicture'
import './HomeProjectCard.scss'

const HomeProjectCard = ({ street, slug, pictures, color }) => {
  const className = classNames('HomeProjectCard', `HomeProjectCard--${color}`)
  const cover = getCoverPicture(pictures)
  return (
    <Link to={'/project/' + slug.current} className="HomeProjectCard_linkWrap">
      <Tilt
        options={{ scale: 1.1, max: 25, speed: 300, perspective: 1500 }}
        className={className}
      >
        <Img fluid={cover.asset.fluid} className="HomeProjectCard_image" />
        <span className="HomeProjectCard_street">{street}</span>
      </Tilt>
    </Link>
  )
}

HomeProjectCard.propTypes = {
  color: PropTypes.oneOf(['main', 'accent', 'accent2', 'brick']),
  street: PropTypes.string.isRequired,
  slug: PropTypes.object.isRequired,
  pictures: PropTypes.array.isRequired,
}

export default HomeProjectCard
