import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useDrag } from 'react-use-gesture'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Button from './Button'
import getCoverPicture from '../utilities/getCoverPicture'
import './MobileHomeProjectCards.scss'

const COLORS = ['main', 'brick', 'accent', 'main-block']

const transition = { type: 'spring', stiffness: 500, damping: 60, mass: 0.9 }

const MobileHomeProjectCard = ({
  street,
  slug,
  pictures,
  color,
  position,
  goBackward,
  goForward,
}) => {
  const className = classNames(
    'MobileHomeProjectCard',
    `MobileHomeProjectCard--${color}`
  )

  const nonActiveProps = {
    y: 0,
    scale: 0.8,
    opacity: 0.7,
    zIndex: 1,
    transition,
  }

  const positions = [
    { x: '-140vw', ...nonActiveProps },
    { x: '-68vw', ...nonActiveProps },
    {
      x: '0',
      opacity: 1,
      scale: 1,
      zIndex: 2,
      transition,
    },
    { x: '68vw', ...nonActiveProps },
    { x: '140vw', ...nonActiveProps },
    { x: '140vw', ...nonActiveProps },
  ]

  // X position of drag
  const [posX, setPosX] = useState(0)
  // is the item tapped
  const [isTapped, setIsTapped] = useState(false)
  // is the user dragging along the y axis
  const [isScrolling, setIsScrolling] = useState(false)

  // get cover
  const cover = getCoverPicture(pictures)

  const bind = useDrag(
    ({ movement, down, swipe, cancel, canceled, tap, axis }) => {
      const [x] = movement
      const [swipeX] = swipe
      // set posX
      if (down) {
        setPosX(x)
      } else {
        setPosX(0)
      }

      // set setIsDragged
      if (axis === 'y') {
        setIsScrolling(true)
      } else {
        setIsScrolling(false)
      }

      // go forwards/back
      if ((x < -80 || swipeX === -1) && !down && !canceled && !tap) {
        cancel()
        goBackward()
      }
      if ((x > 80 || swipeX === 1) && !down && !canceled && !tap) {
        cancel()
        goForward()
      }

      // set tap
      if (tap) {
        setIsTapped(true)
      } else {
        if (isTapped) {
          setIsTapped(false)
        }
      }
    },
    {
      filterTaps: true,
      bounds: {
        left: -140,
        right: 140,
      },
    }
  )

  let animate = { ...positions[position] }
  // the middle active one
  if (position === 2) {
    animate.x = posX

    let posiPosX = posX < 0 ? posX * -1 : posX
    animate.scale = 1 - posiPosX * 0.01 * 0.1
    animate.opacity = 1 - posiPosX * 0.01 * 0.1
  }

  return (
    <motion.div
      className={className}
      initial={animate}
      animate={animate}
      {...bind()}
      style={{ touchAction: isScrolling ? 'auto' : 'none' }}
    >
      {slug.current === '/projects' ? (
        <Link
          to={slug.current}
          className="MobileHomeProjectCard_linkWrap"
          onClick={e => {
            if (!isTapped) {
              e.preventDefault()
            }
          }}
        ></Link>
      ) : (
        <>
          <Link
            to={'/project/' + slug.current}
            className="MobileHomeProjectCard_linkWrap"
            onClick={e => {
              if (!isTapped) {
                e.preventDefault()
              }
            }}
          >
            <Img
              fluid={cover.asset.fluid}
              className="MobileHomeProjectCard_image"
            />
            <span className="MobileHomeProjectCard_street">{street}</span>
          </Link>
        </>
      )}
    </motion.div>
  )
}

MobileHomeProjectCard.propTypes = {
  color: PropTypes.oneOf(['main', 'accent', 'accent2', 'brick', 'main-block']),
  street: PropTypes.string,
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pictures: PropTypes.array,
}

const MobileHomeProjectCards = ({ homeProjects }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const goForward = useCallback(() => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }, [activeIndex])

  const goBackward = useCallback(() => {
    if (activeIndex < homeProjects.length - 1) {
      setActiveIndex(activeIndex + 1)
    }
  }, [activeIndex, homeProjects.length])

  return (
    <>
      <section className="MobileHomeProjectCards">
        {[
          ...homeProjects.slice(0, 3),
          { slug: { current: '/projects' }, color: 'main' },
        ].map((project, idx) => {
          let position
          if (idx === activeIndex - 2) position = 0
          if (idx === activeIndex - 1) position = 1
          if (idx === activeIndex) position = 2
          if (idx === activeIndex + 1) position = 3
          if (idx === activeIndex + 2) position = 4
          if (idx === activeIndex + 3) position = 5
          return (
            <MobileHomeProjectCard
              {...project}
              color={COLORS[idx]}
              position={position}
              key={`mobile-${project.street}`}
              goForward={goForward}
              goBackward={goBackward}
              activeIndex={activeIndex}
            />
          )
        })}
      </section>
      <motion.div
        className="MobileHomeProjectCards_viewAll"
        style={{ pointerEvents: activeIndex === 3 ? 'none' : 'all' }}
        animate={{
          y: activeIndex === 3 ? '-10.6em' : 0,
          color: activeIndex === 3 ? 'white' : '#5b617c',
          scale: activeIndex === 3 ? 1.2 : 1,
          transition,
        }}
      >
        <Link to="/projects">
          <Button color="ghost">
            View all projects
            <motion.span
              className="MobileHomeProjectCards_viewAll_underline"
              initial={{
                background: '#5b617c',
              }}
              animate={{
                opacity: activeIndex === 3 ? 0 : 1,
                background: activeIndex === 3 ? 'white' : '#5b617c',
                transition,
              }}
            />
          </Button>
        </Link>
      </motion.div>
    </>
  )
}

MobileHomeProjectCards.propTypes = {
  homeProjects: PropTypes.array.isRequired,
}

export default MobileHomeProjectCards
