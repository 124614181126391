import React from 'react'
import { Smile, Tool } from './Icons'
import './CompanyFeatures.scss'

const CompanyFeatures = () => (
  <section className="CompanyFeatures">
    <h2>What we offer</h2>
    <div className="CompanyFeatures_featureWrap">
      <div className="CompanyFeatures_feature">
        <div className="CompanyFeatures_svgWrap">£</div>
        <span className="CompanyFeatures_text">Free quotes</span>
      </div>
      <div className="CompanyFeatures_feature">
        <div className="CompanyFeatures_svgWrap">
          <Tool />
        </div>
        <span className="CompanyFeatures_text">Domestic and commercial</span>
      </div>
      <div className="CompanyFeatures_feature">
        <div className="CompanyFeatures_svgWrap">
          <Smile />
        </div>
        <span className="CompanyFeatures_text">No job too small</span>
      </div>
    </div>
  </section>
)

CompanyFeatures.propTypes = {}

export default CompanyFeatures
