import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { ArrowRight } from './Icons'
import Button from './Button'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import './Testimonials.scss'

const TESTIMONIAL_AMOUNT = 4

const item = {
  hidden: { opacity: 0, y: 40, scale: 0.5 },
  visible: i => {
    const DELAY_INCREMENT = 0.2
    const delay = (i % TESTIMONIAL_AMOUNT) * DELAY_INCREMENT
    return {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay,
      },
    }
  },
}

const Testimonials = ({ testimonials }) => {
  const [amountShown, setAmountShown] = useState(TESTIMONIAL_AMOUNT)
  return (
    <section className="Testimonials">
      <h2 className="Testimonials_heading">What people are saying</h2>
      <div className="Testimonials_wrap">
        {testimonials.slice(0, amountShown).map((testimonial, i) => (
          <motion.div
            className="Testimonial"
            whileTap={{ scale: 0.95 }}
            key={testimonial.testimonial}
            custom={i}
            variants={item}
            initial="hidden"
            animate="visible"
          >
            <Link
              to={'/project/' + testimonial.slug.current}
              className="Testimonial_linkWrap"
            >
              <div className="Testimonial_textWrap">
                <span className="Testimonial_name">{testimonial.name}</span>
                <p className="Testimonial_body">{testimonial.testimonial}</p>
              </div>
              <div className="Testimonial_viewWrap">
                <span className="Testimonial_viewText">View project</span>
                <span className="Testimonial_viewArrow">
                  <ArrowRight />
                </span>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
      {amountShown < testimonials.length && (
        <Button
          color="ghost"
          onClick={() => setAmountShown(amountShown + TESTIMONIAL_AMOUNT)}
        >
          Show more testimonials
        </Button>
      )}
    </section>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      testimonial: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      slug: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
}

export default Testimonials
