import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import Button from '../components/Button'
import HomeProjectCard from '../components/HomeProjectCard'
import Testimonials from '../components/Testimonials'
import CompanyFeatures from '../components/CompanyFeatures'
import GetInTouch from '../components/GetInTouch'
import MobileHomeProjectCards from '../components/MobileHomeProjectCards'
import SEO from '../components/seo'
import METADATA from '../metadata/metadata'
import './index.scss'

const COLORS = ['main', 'brick', 'accent']

const matchTestimonialsWithSlugs = (projects, testimonials) => {
  const testimonialsWithSlugs = testimonials.reduce((acc, testimonial) => {
    const matchedProject = projects.find(project => {
      if (project.testimonial) {
        return project.testimonial.id === testimonial.id
      } else {
        return false
      }
    })

    acc.push({
      ...testimonial,
      slug: matchedProject.slug,
    })

    return acc
  }, [])

  return testimonialsWithSlugs
}

const IndexPage = ({
  data: {
    homeProjects: { nodes: homeProjects },
    allSanityTestimonial: { nodes: testimonials },
    allProjects: { nodes: allProjects },
  },
}) => {
  const testimonialsWithSlugs = useMemo(
    () => matchTestimonialsWithSlugs(allProjects, testimonials),
    [allProjects, testimonials]
  )

  return (
    <div className="home">
      <SEO
        title={METADATA.home.title}
        description={METADATA.home.description}
      />
      <section className="home_heading">
        <h2 className="home_heading_title">Trusted local builder</h2>
        <p className="home_heading_body">
          A trusted local builder for over 35 years, AGHicks Building Services
          covers Northampton, Wellingborough, Kettering, and the surrounding
          areas.
        </p>
        <Link to="/contact">
          <Button color="accent2">Get in touch</Button>
        </Link>
      </section>
      <section className="home_selectedProjects">
        <div className="home_selectedProjects_wrap">
          {homeProjects.slice(0, 3).map((project, idx) => (
            <HomeProjectCard
              {...project}
              color={COLORS[idx]}
              key={project.street}
            />
          ))}
        </div>
        <MobileHomeProjectCards homeProjects={homeProjects} />
        <Link to="/projects" className="home_selectedProjects_view">
          <Button color="ghost">View all projects</Button>
        </Link>
      </section>
      <CompanyFeatures />
      <Testimonials testimonials={testimonialsWithSlugs} />
      <GetInTouch />

      {/* fallback font for iOS */}
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </div>
  )
}

export const query = graphql`
  query HomeQuery {
    allSanityTestimonial(sort: { fields: [_createdAt], order: DESC }) {
      nodes {
        name
        testimonial
        id
      }
    }
    allProjects: allSanityProject {
      nodes {
        testimonial {
          id
        }
        slug {
          current
        }
      }
    }
    homeProjects: allSanityProject(
      filter: { home_project: { eq: true } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        street
        pictures {
          cover
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default IndexPage
